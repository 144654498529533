<template>
  <div class="divContainer">
    <div v-loading="loading" class="divContent">
      <el-form v-if="!loading" ref="mainFrom" :model="formData" :rules="rules" label-width="160px">
        <el-form-item prop="Headline" label="标题">
          <span v-if="isView" v-text="formData.Headline"></span>
          <el-input v-else v-model="formData.Headline" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="BannerImg" label="横幅图片">
              <el-image v-if="isView" :src="formData.BannerImg"></el-image>
              <!-- <picture-selector v-model="formData.CoverImage" name="CoverImage" ref="CoverImage" remarks="527*296" @input="updateForm"> -->
              <picture-selector v-else v-model="formData.BannerImg" @input="updateFileImg" name="BannerImg" remarks="1920*400"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="Summary" label="点横幅跳转链接">
          <span v-if="isView" v-text="formData.LinkUrl"></span>
          <el-input v-else v-model="formData.LinkUrl" placeholder="请输入跳转链接"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="Sort" label="显示排序">
              <span v-if="isView" v-text="formData.Sort"></span>
              <el-input v-else v-model="formData.Sort" type="number" placeholder="请输入排序优先级，数字越大排在越前面"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="devOperate">
      <slot name="buttons">
        <el-button v-if="!this.isView" :loading="isUploading" type="primary" icon="fa fa-save" class="btnBottom" @click="submitForm">
          保存
        </el-button>
        <el-button :loading="isUploading" icon="fa fa-reply" class="btnBottom" @click="cancle">
          {{ this.isView ? "返回" : "取消" }}
        </el-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { createEntity, getInfo, updateEntity } from "@/api/banner";
// import PictureSelector from "@/components/PictureSelector.vue";
import PictureSelector from "@/components/base/PictureSelector";
export default {
  name: "BannerDetail",
  components: {
    PictureSelector,
  },
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUploading: false,
      loading: false,
      id: "",
      formData: {
        Headline: "",
        LinkUrl: "",
        Sort: 0,
        BannerImg: ""
      },
      rules: {
        Headline: [{ required: true, message: "请输入标题", trigger: "blur" }],
        BannerImg: [{ required: true, message: "请选择横幅图", trigger: "change" }]
      }
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;
      try {
        // 查看和编辑时需要获取详情
        if (this.id) {
          const res = await getInfo({ id: this.id });
          if (res.IsSuccess) {
            this.formData = res.Result;
          }
        } else {
          // this.formData.ReleaseTime = this.getNowDate();
          // console.log("formData:", this.formData);
        }
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    updateFileImg(name, file) {
      this.formData.BannerImg = file;
    },
    submitForm() {
      this.$refs.mainFrom.validate(async valid => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          const postData = Object.assign({}, this.formData);
          try {
            if (this.isEdit) {
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await createEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "温馨提示"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
          });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.divContainer {
  background-color: #fff !important;
  padding: 24px 46px !important;
  padding-left: 0 !important;
  border-radius: 8px !important;
  .divContent {
    min-height: 60vh !important;
  }
  .devOperate {
    display: flex;
    justify-content: center;
  }
}
/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
/deep/ .el-form-item__content {
  line-height: 40px !important;
}
</style>
