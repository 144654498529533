<template>
  <div class="form-header">
    <div class="header-title">
      <div>
        <template v-for="(item, i) in titleList">
          <span v-if="item.isCur" class="router-item router-cur" :key="i">{{
            item.text
          }}</span>
          <span v-else class="router-item" @click="$router.go(-1)" :key="i"
            >{{ item.text }} /
          </span>
        </template>
      </div>
      <div><slot name="btn"></slot></div>
    </div>
    <!-- <el-button type="text" icon="el-icon-back" @click="$router.go(-1)"
      >返回</el-button
    > -->
  </div>
</template>

<script>
export default {
  name: "ContentHeader",
  props: {
    titleList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 120px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  // padding-top: 16px;
  // padding-bottom: 8px;
  padding: 24px;
  box-shadow: 0px 1px 0px 0px #eeeeee;
  border-radius: 8px 8px 0px 0px;
}
.router-item {
  font-weight: 400;
  font-size: 14px;
  color: #bfbfbf;
  user-select: none;
  cursor: pointer;
}
.router-cur {
  color: #333333;
}
.div-header-menu {
  /deep/ .el-menu-demo {
    border-bottom: none !important;
  }
}
</style>
